import React, { useRef, useState, useEffect } from "react";
import { useField, useFormikContext, FieldHookConfig } from "formik";
import { startCase, keyBy } from "lodash";
import DatePicker from "react-datepicker";
import { NumericFormat } from "react-number-format";
import { CustomAutosuggest } from "./CustomAutosuggest";
import "react-datepicker/dist/react-datepicker.css";
import { Overlay, Tooltip } from "react-bootstrap";

import { ComboBox, IComboBoxOption, TagPicker, ITag, Toggle } from "@fluentui/react";

import { isValidYYYYMMDDHHMMSS, usePrevious } from "../../common/Utils";

type NumberInputPropTypes = { field: any; form: any; className: string };

export const NumberInput = ({ field, form, className }: NumberInputPropTypes): React.ReactElement => {
    // const { touched, errors } = form;
    return (
        <NumericFormat
            name={field.name}
            value={typeof field.value !== "undefined" ? field.value : null}
            className={className}
            onValueChange={(e) => form.setFieldValue(field.name, typeof e.floatValue !== "undefined" ? e.floatValue : null, true)}
            type="text"
            thousandSeparator=" "
            decimalSeparator="."
        />
    );
};

export const Checkbox = (props) => <input type="checkbox" {...props} />;

type CheckboxItemPropTypes = {
    name: string;
    defaultValue?: boolean;
    label: string;
    className?: string;
    disabled?: boolean;
};

export const CheckboxItem = ({ name, label, className, disabled }: CheckboxItemPropTypes): React.ReactElement => {
    const [field] = useField(name);
    return (
        <div className="form-check">
            <input
                id={name}
                name={name}
                type="checkbox"
                disabled={disabled}
                className={`form-check-input ${className}`}
                checked={field.value}
                value={field.value}
                {...field}
            />
            <label htmlFor={name} className="form-check-label">
                &nbsp;{label}
            </label>
        </div>
    );
};

const instrumentToText = (d): string =>
    [d.name, d.longName !== d.name ? d.longName : null, d.bloombergTicker, d.isin].filter((d) => !!d).join(" ");

type SubmitButtonPropTypes = { disabled: boolean; label?: string; className?: string };

export const SubmitButton = ({
    disabled,
    label = "Save",
    className = "btn btn-primary btn-sm"
}: SubmitButtonPropTypes): React.ReactElement => {
    return (
        <button type="submit" disabled={disabled} className={className}>
            {label}
        </button>
    );
};

type LabelPropTypes = { name: string; value?: string | React.ReactElement };

export const Label = ({ name, value }: LabelPropTypes): React.ReactElement => {
    if (!value) {
        value = startCase(name);
    }
    return <label htmlFor={name}>{value}</label>;
};

type TextFieldPropTypes = FieldHookConfig<string> & { label: any; className: string; disabled: boolean; readOnly?: boolean };

export function TextField({ label, className, disabled, ...props }: TextFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            {typeof label === "string" ? <Label name={field.name} value={label} /> : label}
            <input
                id={field.name}
                name={field.name}
                className="form-control"
                readOnly={props.readOnly}
                disabled={disabled}
                {...field}
                value={field.value || ""}
                type={props.type || "text"}
                autoComplete={props.autoComplete || null}
                spellCheck={props.spellCheck || null}
            />
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
        </div>
    );
}

type NonAutoFillTextFieldPropTypes = FieldHookConfig<string> & { label: string; className: string; disabled: boolean };

export function NonAutoFillTextField({ label, className, disabled, ...props }: NonAutoFillTextFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            <Label name={field.name} value={label} />
            <input
                id={field.name}
                name={field.name}
                className="form-control auto-complete-off"
                autoComplete="off"
                disabled={disabled}
                {...field}
                value={field.value || ""}
            />
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
        </div>
    );
}

interface FieldOptionType {
    key: string;
    value: string;
}

type SelectFieldPropTypes = FieldHookConfig<string> & {
    label?: any;
    className: string;
    disabled: boolean;
    tooltips?: Record<string, string>;
    options: string[] | FieldOptionType[];
    onSelectedChange?: (value: any) => void;
};

export const SelectField = ({
    label,
    className,
    disabled,
    tooltips,
    options,
    onSelectedChange,
    ...props
}: SelectFieldPropTypes): React.ReactElement => {
    const [field, meta] = useField(props);
    const { value } = meta;
    const previousValue = usePrevious(field.value);

    useEffect(() => {
        if (previousValue && previousValue !== value) {
            if (onSelectedChange) {
                onSelectedChange(value);
            }
        }
    }, [value, onSelectedChange, previousValue]);

    let keys: Record<any, any> = {};
    if (options.length > 0) {
        if (typeof options[0] === "object") {
            keys = keyBy(options, "key");
        } else {
            options.forEach((option) => {
                keys[option] = option;
            });
        }
    }

    let exist = false;
    if (field.value in keys) {
        exist = true;
    }

    const allOptions = [...options];
    if (!exist) {
        const newOption = { key: field.value, value: field.value ? field.value : disabled ? "" : "NON EXISTING OPTION" };
        allOptions.unshift(newOption);
    }

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            {typeof label === "undefined" ? (
                <Label name={field.name} />
            ) : typeof label === "string" ? (
                <Label name={field.name} value={label} />
            ) : (
                label
            )}
            <select
                id={field.name}
                name={field.name}
                className="form-control form-select"
                disabled={disabled}
                {...field}
                value={field.value || ""}
                title={tooltips && field.value && field.value in tooltips ? tooltips[field.value] : null}
            >
                {allOptions.map((d, i) => {
                    const key: string = typeof d === "string" ? d : d.key !== null ? d.key : "";
                    return (
                        <option key={i} value={key} title={tooltips && key ? tooltips[key] : null}>
                            {typeof d === "string" ? d : d.value}
                        </option>
                    );
                })}
            </select>
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
            {!exist && !disabled ? <div className="form-field-error">'{field.value}' is not an existing option</div> : null}
        </div>
    );
};

type NullableSelectFieldPropTypes = FieldHookConfig<string> & {
    label: any;
    className: string;
    disabled: boolean;
    nullKeyName?: string;
    options: { key: string; value: string }[];
};

export function NullableSelectField({
    label,
    className,
    disabled,
    options,
    nullKeyName,
    ...props
}: NullableSelectFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    const exist = options.some((v) => v.key === field.value || !field.value || field.value === "None" || field.value === nullKeyName);

    let arrayOfOptions;
    if (nullKeyName) {
        arrayOfOptions = [{ key: nullKeyName, value: null }, ...options];
    } else {
        arrayOfOptions = [{ key: "None", value: null }, ...options];
    }
    if (!exist) {
        const newOption = { key: field.value, value: field.value ? field.value : "NON EXISTING OPTION" };
        arrayOfOptions.push(newOption);
    }

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            {typeof label === "string" ? <Label name={field.name} value={label} /> : label}
            <select
                id={field.name}
                name={field.name}
                className="form-control form-select"
                disabled={disabled}
                {...field}
                value={field.value === null ? "None" : field.value}
                onChange={(event) => {
                    if (event.target.value === "" || event.target.value === "None") {
                        setFieldValue(field.name, null, true);
                    } else {
                        setFieldValue(field.name, event.target.value, true);
                    }
                }}
            >
                {arrayOfOptions.map((item: { key: string; value: string }) => (
                    <option key={item.key} value={item.value}>
                        {item.key}
                    </option>
                ))}
            </select>
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
            {!exist ? <div className="form-field-error">'{field.value}' is not an existing option</div> : null}
        </div>
    );
}

type PasswordFieldPropTypes = FieldHookConfig<string> & { label: string; className: string; disabled: boolean };

export function PasswordField({ label, className, disabled, ...props }: PasswordFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            <Label name={field.name} value={label} />
            <input
                id={field.name}
                name={field.name}
                className="form-control"
                disabled={disabled}
                {...field}
                value={field.value || ""}
                type="password"
            />
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
        </div>
    );
}

type MultipleSelectFieldPropTypes = FieldHookConfig<string[]> & {
    label: string | React.ReactElement;
    className: string;
    disabled: boolean;
    options: any[];
    tooltips?: Record<string, string>;
    size: number;
};

export function MultipleSelectField({
    label,
    className,
    disabled,
    options,
    tooltips,
    size,
    ...props
}: MultipleSelectFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);

    const allKeys = options.map((i) => (typeof i === "string" ? i : i.key));
    const missingValues = field.value ? field.value.filter((v) => !allKeys.includes(v)) : [];

    const missingOptions = missingValues.length
        ? missingValues.map((v) => {
              return { key: v, value: "NOT EXISTING OPTION" };
          })
        : [];

    const allOptions = [...options, ...missingOptions];

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            <Label name={field.name} value={label} />
            <select
                className="form-control form-select"
                id={field.name}
                name={field.name}
                disabled={disabled}
                {...field}
                multiple
                size={size}
            >
                {allOptions.map((d, i) => {
                    const key = typeof d === "string" ? d : d.key !== null ? d.key : "";
                    const value = typeof d === "string" ? d : d.value;
                    return (
                        <option key={i} value={key} title={tooltips && key ? tooltips[key] : null}>
                            {value}
                        </option>
                    );
                })}
            </select>
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
            {missingValues.length > 0 ? <div className="form-field-error">'{missingValues}' is not an existing option</div> : null}
        </div>
    );
}

type NullableMultiSelectFieldPropTypes = FieldHookConfig<string[] | null> & {
    label: string;
    className: string;
    disabled: boolean;
    options: any[];
    tooltips?: Record<string, string>;
    size: number;
};

export function NullableMultiSelectField({
    label,
    className,
    disabled,
    options,
    size,
    tooltips,
    ...props
}: NullableMultiSelectFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    const isNullValue = (v: any) => {
        if (!v) {
            return true;
        }
        if (Array.isArray(v) && v.length === 1 && v[0] === "") {
            return true;
        }
        return false;
    };

    const [multiple, setMultipleMode] = useState(!isNullValue(field.value));

    useEffect(() => {
        const isNull = isNullValue(field.value);
        if (isNull && field.value !== null) {
            setFieldValue(field.name, null, true);
        }
        if (!isNull && !Array.isArray(field.value)) {
            setFieldValue(field.name, [field.value], true);
        }
        if (multiple !== !isNull) {
            setMultipleMode(!isNull);
        }
    }, [field.name, field.value, multiple, setFieldValue, setMultipleMode]);

    // add 'null' option
    const nullableOptions = [...[{ key: "", value: "" }], ...options];

    const allKeys = nullableOptions.map((i) => (typeof i === "string" ? i : i.key));
    const missingValues = field.value && Array.isArray(field.value) ? field.value.filter((v) => !allKeys.includes(v)) : [];
    const missingOptions = missingValues.map((v) => {
        return { key: v, value: "NOT EXISTING OPTION" };
    });

    const allOptions = [...nullableOptions, ...missingOptions];

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            <Label name={field.name} value={label} />
            <select
                className="form-control form-select"
                id={field.name}
                name={field.name}
                disabled={disabled}
                {...field}
                multiple={multiple}
                size={size}
                value={multiple ? field.value || [] : ""}
            >
                {allOptions.map((d, i) => {
                    const key = typeof d === "string" ? d : d.key;
                    const value = typeof d === "string" ? d : d.value;
                    return (
                        <option key={i} value={key} title={tooltips && key ? tooltips[key] : null}>
                            {value}
                        </option>
                    );
                })}
            </select>
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
            {missingValues.length > 0 ? <div className="form-field-error">'{missingValues}' is not an existing option</div> : null}
        </div>
    );
}

type TextAreaFieldPropTypes = FieldHookConfig<string> & {
    label: string;
    className: string;
    disabled: boolean;
    rows: number;
    cols: number;
};

export function TextAreaField({ label, className, disabled, rows, cols, ...props }: TextAreaFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            <Label name={field.name} value={label} />
            <textarea className="form-control" id={field.name} name={field.name} disabled={disabled} {...field} rows={rows} cols={cols}>
                {field.value}
            </textarea>
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
        </div>
    );
}

type IntegerFieldPropTypes = FieldHookConfig<string> & {
    label: string | React.ReactElement;
    className: string;
    disabled: boolean;
    min: number;
    max: number;
};

export function IntegerField({ label, className, disabled, min, max, ...props }: IntegerFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            {typeof label === "string" ? <Label name={field.name} value={label} /> : label}
            <input
                type="number"
                className="form-control"
                id={field.name}
                name={field.name}
                disabled={disabled}
                {...field}
                min={min}
                max={max}
                value={field.value == "0" ? field.value : field.value || ""} // '0' is visible
            ></input>
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
        </div>
    );
}

type DateFieldPropTypes = FieldHookConfig<string> & { label: string; className: string; disabled: boolean; minDate?: string };

export function DateField({ label, className, disabled, minDate, ...props }: DateFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    const thisMinDate = minDate ? new Date(minDate) : null; // is this really correct for all timezones?
    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            <div className="row-col">
                <Label name={field.name} value={label} />
            </div>
            <div className="row-col">
                <DatePicker
                    name={field.name}
                    calendarStartDay={1}
                    className="form-control"
                    selected={(field.value && new Date(field.value)) || null}
                    onChange={(val) => {
                        if (val instanceof Date) {
                            //https://github.com/Hacker0x01/react-datepicker/issues/1018#issuecomment-647586438
                            const offsetDate = new Date(val.getTime() - val.getTimezoneOffset() * 60000);
                            setFieldValue(field.name, offsetDate.toISOString().slice(0, 10), true);
                        } else {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            setFieldValue(field.name, val.toString().slice(0, 10), true);
                            // sorry I dont believe the type help here
                        }
                    }}
                    strictParsing
                    dateFormat="yyyy-MM-dd"
                    disabled={disabled}
                    minDate={thisMinDate}
                />
            </div>
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
        </div>
    );
}

type TimestampFieldPropTypes = FieldHookConfig<string> & { label: string; className: string; disabled: boolean };

export function TimestampField({ label, className, disabled, ...props }: TimestampFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            <div className="form-row">
                <Label name={field.name} value={label + " (local time)"} />
            </div>
            <div className="form-row">
                <DatePicker
                    name={field.name}
                    calendarStartDay={1}
                    className="form-control"
                    selected={
                        isValidYYYYMMDDHHMMSS(field.value)
                            ? new Date(field.value)
                            : field.value && new Date(field.value)
                              ? new Date(new Date(field.value).setHours(12, 0, 0, 0))
                              : null
                    }
                    onChange={(val) => {
                        if (val instanceof Date) {
                            setFieldValue(field.name, val.toISOString(), true);
                        } else {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            setFieldValue(field.name, val.toString(), true);
                            // sorry I dont believe the type help here
                        }
                    }}
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    timeFormat="HH:mm:ss"
                    disabled={disabled}
                    showTimeInput={true}
                />
                {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
            </div>
        </div>
    );
}

type NullableDateFieldPropTypes = FieldHookConfig<string> & { label: string; className: string; disabled: boolean };
export function NullableDateField({ label, className, disabled, ...props }: NullableDateFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();
    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            <div className="form-row">
                <div className="form-group col">
                    <Label name={field.name} value={label} />
                </div>
            </div>
            {field.value ? (
                <div className="form-row">
                    <div className="form-group col-1">
                        <input
                            id={field.name}
                            type="checkbox"
                            name={field.name}
                            className=""
                            disabled={disabled}
                            checked={field.value ? true : false}
                            onChange={() => {
                                setFieldValue(field.name, null, true);
                            }}
                        />
                    </div>
                    <div className="form-group col-11">
                        <DatePicker
                            name={field.name}
                            className="form-control"
                            selected={(field.value && new Date(field.value)) || null}
                            onChange={(val) => setFieldValue(field.name, val.toISOString().slice(0, 10), true)}
                            dateFormat={"yyyy-MM-dd"}
                            disabled={disabled}
                        />
                    </div>
                </div>
            ) : (
                <div className="form-row">
                    <div className="form-group col-1">
                        <input
                            id={field.name}
                            type="checkbox"
                            name={field.name}
                            className=""
                            disabled={disabled}
                            checked={field.value ? true : false}
                            onChange={() => {
                                setFieldValue(field.name, new Date().toISOString().slice(0, 10), true);
                            }}
                        />
                    </div>
                    <div className="form-group col-11">
                        <DatePicker
                            name={field.name}
                            className="form-control"
                            selected={(field.value && new Date(field.value)) || null}
                            onChange={(val) => setFieldValue(field.name, val.toISOString().slice(0, 10), true)}
                            dateFormat={"yyyy-MM-dd"}
                            disabled={true}
                        />
                    </div>
                </div>
            )}
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
        </div>
    );
}

type NullableToggleDateFieldPropTypes = FieldHookConfig<string> & { label: string; className?: string; disabled: boolean };
export function NullableToggleDateField({ label, className, disabled, ...props }: NullableToggleDateFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    const onChange = (ev, checked?: boolean) => {
        checked ? setFieldValue(field.name, new Date().toISOString().slice(0, 10), true) : setFieldValue(field.name, null, true);
    };

    return (
        <div className={className || ""}>
            {field.value ? (
                <div>
                    <Toggle defaultChecked inlineLabel onText={label} offText={label} onChange={onChange} />
                    <div>
                        <DatePicker
                            name={field.name}
                            selected={(field.value && new Date(field.value)) || null}
                            onChange={(val) => setFieldValue(field.name, val.toISOString().slice(0, 10), true)}
                            dateFormat={"yyyy-MM-dd"}
                            disabled={disabled}
                        />
                    </div>
                </div>
            ) : (
                <div>
                    <Toggle inlineLabel onText={label} offText={label} onChange={onChange} />
                </div>
            )}

            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
        </div>
    );
}

type NumberFieldPropTypes = FieldHookConfig<string> & {
    label: string | React.ReactElement;
    className: string;
    disabled: boolean;
    changeTolerancePercent?: number;
};
export function NumberField({ label, className, disabled, changeTolerancePercent, ...props }: NumberFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();
    const [oldValue] = useState(field.value ? parseFloat(field.value) : null);
    const [withinChangeTolerance, setWithinChangeTolerance] = useState(changeTolerancePercent || true);

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            {typeof label === "string" ? <Label name={field.name} value={label} /> : label}
            <NumericFormat
                name={field.name}
                value={typeof field.value !== "undefined" ? field.value : null}
                className="form-control"
                onValueChange={(e) => {
                    if (e.floatValue || e.value === "0") {
                        if (changeTolerancePercent && oldValue && field.value) {
                            const changeTolerance = Math.abs((oldValue * changeTolerancePercent) / 100);
                            const inTolerance = e.floatValue > oldValue - changeTolerance && e.floatValue < oldValue + changeTolerance;
                            setWithinChangeTolerance(inTolerance);
                        }
                        setFieldValue(field.name, e.floatValue, true);
                    } else if (!e.floatValue && !e.value.startsWith("-")) {
                        setFieldValue(field.name, e.floatValue, true);
                    }
                }}
                disabled={disabled}
                thousandSeparator=" "
                decimalSeparator="."
            />
            {!withinChangeTolerance ? <div className="form-field-error">Note: value is over change tolerance!</div> : null}
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
        </div>
    );
}

type InstrumentInputPropTypes = FieldHookConfig<string> & {
    selected: string;
    className: string;
    values: ({ _id: string; name: string; longName: string; isin: string; blombergTicker } & {
        [key: string]: any;
    })[];
    onChange: (
        e: { _id: string; name: string; longName: string; isin: string; blombergTicker } & {
            [key: string]: any;
        }
    ) => void;
};

export const InstrumentInput = ({ selected, className, values, onChange }: InstrumentInputPropTypes): React.ReactElement => {
    return (
        <CustomAutosuggest<
            { _id: string; name: string; longName: string; isin: string; blombergTicker } & {
                [key: string]: any;
            }
        >
            className={className}
            selected={values.find((d) => d._id === selected)}
            values={values}
            toDisplayText={(d) => d.name}
            toSuggestionText={instrumentToText}
            onChange={(val) => {
                if (onChange) {
                    onChange(val);
                }
            }}
        />
    );
};

type SearchListFieldPropTypes = FieldHookConfig<string> & {
    label: any;
    className: string;
    disabled: boolean;
    options: ({ _id: string; name: string } & {
        [key: string]: any;
    })[];
    onChange?: (e: string) => void;
    initialInputFocus?: boolean;
};

export function SearchListField({
    label,
    className,
    disabled,
    options,
    initialInputFocus = true,
    ...props
}: SearchListFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    const exist = options.some((v) => v._id === field.value || !field.value);
    const allOptions = [...options];
    if (!exist) {
        const newOption = { _id: field.value, name: field.value ? field.value : "NON EXISTING OPTION" };
        allOptions.push(newOption);
    }

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            {typeof label === "string" ? <Label name={field.name} value={label} /> : label}
            <CustomAutosuggest<
                { _id: string; name: string } & {
                    [key: string]: any;
                }
            >
                name={field.name}
                className={className}
                selected={allOptions.find((d) => d._id === field.value)}
                values={allOptions}
                toDisplayText={(d) => d.name}
                toSuggestionText={instrumentToText}
                onChange={(val) => {
                    setFieldValue(field.name, val === null ? null : val._id, true);
                    if (props.onChange) {
                        props.onChange(val === null ? null : val._id);
                    }
                }}
                disabled={disabled}
                initialInputFocus={initialInputFocus}
            />
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
            {!exist ? <div className="form-field-error">'{field.value}' is not an existing option</div> : null}
        </div>
    );
}

type TransactionFieldPropTypes = {
    label: string | React.ReactElement;
    className: string;
    disabled: boolean;
    options: ({
        _id: string;
        name: string;
        source: string;
    } & {
        [key: string]: any;
    })[];
};

const transactionToText = (d: { name: any; source: any }): string => [d.name, d.source].filter((d) => d !== null && d !== "").join(" ");

export function TransactionField({
    label,
    className,
    disabled,
    options,
    ...props
}: FieldHookConfig<string> & TransactionFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    const exist = options.some((v) => v._id === field.value || !field.value);

    const allOptions = [...options];
    if (!exist) {
        const newOption = { _id: field.value, name: field.value ? field.value : "NON EXISTING OPTION", source: null };
        allOptions.push(newOption);
    }

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            {typeof label === "string" ? <Label name={field.name} value={label} /> : label}
            <CustomAutosuggest<
                {
                    _id: string;
                    name: string;
                    source: string;
                } & {
                    [key: string]: any;
                }
            >
                name={field.name}
                className={className}
                selected={allOptions.find((d) => d._id === field.value)}
                values={allOptions}
                toDisplayText={(d) => d.name}
                toSuggestionText={transactionToText}
                onChange={(val) => {
                    setFieldValue(field.name, val === null ? null : val._id, true);
                }}
                disabled={disabled}
            />
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
            {!exist ? <div className="form-field-error">'{field.value}' is not an existing option</div> : null}
        </div>
    );
}

type CheckBoxFieldPropTypes = FieldHookConfig<any> & { label: string; className: string; disabled: boolean };

export function CheckBoxField({ label, className, disabled, ...props }: CheckBoxFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            <div className="row">
                <div className="col-12">
                    <input
                        id={field.name}
                        type="checkbox"
                        name={field.name}
                        className="form-checkbox"
                        disabled={disabled}
                        checked={field.value}
                        {...field}
                        value={field.value || ""}
                    />
                    <Label name={field.name} value={label} />
                </div>
            </div>
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
        </div>
    );
}

export function compareCurrency(c1, c2): number {
    const hotList = ["SEK", "EUR", "USD", "NOK", "DKK", "GBP"].reduce((dict, c, i) => {
        dict[c] = i + 1;
        return dict;
    }, {});
    if (hotList[c1]) {
        return hotList[c2] ? hotList[c1] - hotList[c2] : -1;
    }
    if (hotList[c2]) {
        return 1;
    }
    return c1 < c2 ? -1 : c1 > c2 ? 1 : 0;
}

export { CustomAutosuggest };

type CopyClipboardButtonPropTypes = { id: string; text: string };

export function CopyClipboardButton({ id, text }: CopyClipboardButtonPropTypes): React.ReactElement {
    const target = useRef(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const handleCopy = () => {
        if (window["clipboardData"] && window["clipboardData"].setData) {
            // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
            setTooltipOpen(true);
            setTimeout(() => {
                setTooltipOpen(false);
            }, 2000);
            return window["clipboardData"].setData("Text", text);
        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            const textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy"); // Security exception may be thrown by some browsers.
            } catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return false;
            } finally {
                setTooltipOpen(true);
                setTimeout(() => {
                    setTooltipOpen(false);
                }, 2000);
                document.body.removeChild(textarea);
            }
        }
    };

    return (
        <div>
            <Overlay target={target.current} show={tooltipOpen} placement="bottom">
                {({ ...props }) => (
                    <Tooltip id={id} {...props}>
                        Copied!
                    </Tooltip>
                )}
            </Overlay>
            <button
                id={id}
                ref={target}
                type="button"
                className="btn btn-light btn-sm float-end"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Copy to Clipboard"
                onClick={handleCopy}
            >
                <svg
                    className="bi bi-clipboard"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
                    />
                    <path
                        fillRule="evenodd"
                        d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
                    />
                </svg>
            </button>
        </div>
    );
}

type JsonFieldPropTypes = FieldHookConfig<string> & {
    label: string;
    className: string;
    rows?: number;
};

export function JsonField({ label, className, rows = 15, ...props }: JsonFieldPropTypes): React.ReactElement {
    const [field, meta, helpers] = useField(props);
    const { setFieldValue } = useFormikContext();

    const validateJson = (value: string) => {
        try {
            JSON.parse(value);
            return undefined; // no error
        } catch (e) {
            return "Invalid JSON format";
        }
    };

    const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        const error = validateJson(event.target.value);
        helpers.setError(error);
        if (!error) {
            helpers.setValue(JSON.parse(event.target.value)); // Update the form value with the parsed JSON object
        }
        field.onBlur(event); // Call the default onBlur handler
    };

    const handleChange = (e) => {
        const newValue = e.target.value;

        if (typeof newValue === "string") {
            try {
                const o = JSON.parse(newValue);
                setFieldValue(field.name, o, true);
                return undefined; // no error
            } catch (e) {
                setFieldValue(field.name, newValue, true);
            }
        } else {
            setFieldValue(field.name, newValue, true);
        }
    };

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            <Label name={field.name} value={label} />
            <textarea
                className={`form-control ${meta.error ? "is-invalid" : ""}`}
                id={field.name}
                name={field.name}
                rows={rows}
                value={typeof field.value === "string" ? field.value : JSON.stringify(field.value, null, 2)}
                onBlur={handleBlur} // Override the onBlur to validate and parse JSON
                onChange={handleChange} // Default onChange handler
            />
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
        </div>
    );
}

type SearchMultipleSelectFieldPropTypes = FieldHookConfig<string[]> & {
    label: any;
    className: string;
    disabled: boolean;
    options: {
        key: string;
        value: string;
        text: string;
    }[];
    onChange?: any;
    itemLimit?: number;
};

export function SearchMultipleSelectField({
    label,
    className,
    disabled,
    options,
    onChange,
    itemLimit = 10,
    ...props
}: SearchMultipleSelectFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    const allKeys = options.map((i) => (typeof i === "string" ? i : i.key));
    const missingValues = field.value.filter((v) => !allKeys.includes(v));

    const missingOptions = missingValues.map((v) => {
        return { key: v, value: "NOT EXISTING OPTION", text: "NOT EXISTING OPTION" };
    });

    const allOptions = [...options, ...missingOptions];

    const tags: ITag[] = allOptions.map((s) => {
        return { key: s.key, name: s.text };
    });

    const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
        if (!tagList || !tagList.length || tagList.length === 0) {
            return false;
        }
        return tagList.some((compareTag) => compareTag.key === tag.key);
    };

    const onResolveSuggestions = (filter: string, selectedItems: ITag[]): ITag[] => {
        const suggestions = filter ? tags.filter((tag) => tag.name.toLowerCase().includes(filter.toLowerCase())) : [];
        return suggestions.filter((tag) => !listContainsTagList(tag, selectedItems));
    };

    const onEmptyInputFocus = (selectedItems?: ITag[]) => {
        // Note: we take first 200 items only
        return tags.slice(0, 200).filter((tag) => !listContainsTagList(tag, selectedItems));
    };

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            {typeof label === "string" ? <Label name={field.name} value={label} /> : label}
            <div>
                <TagPicker
                    key={field.value?.join(".")}
                    removeButtonAriaLabel="Remove"
                    selectionAriaLabel="Select ...."
                    onResolveSuggestions={onResolveSuggestions}
                    removeButtonIconProps={{ iconName: "Delete" }}
                    itemLimit={itemLimit}
                    pickerCalloutProps={{ doNotLayer: true }}
                    defaultSelectedItems={field.value.map((id) => {
                        const option = allOptions.find((x) => x.key === id);
                        return { key: option.key, name: option.value, text: option.text };
                    })}
                    onChange={(items) => {
                        setFieldValue(field.name, items === null ? null : items.map((v) => v.key), true);
                        if (onChange) {
                            onChange(
                                items === null
                                    ? null
                                    : items.map((item) => {
                                          return { value: item.key, label: item.name };
                                      })
                            );
                        }
                    }}
                    onEmptyResolveSuggestions={onEmptyInputFocus}
                    disabled={disabled}
                />
            </div>
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
        </div>
    );
}

interface MaybeInListFieldOptionType {
    key: string;
    value: string;
}
type MaybeInListFieldPropTypes = FieldHookConfig<string> & {
    label?: any;
    className: string;
    disabled: boolean;
    options: MaybeInListFieldOptionType[];
};

export function MaybeInListField({ label, className, disabled, options, ...props }: MaybeInListFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    const comboOptions: IComboBoxOption[] = options.map((o) => {
        return { key: o.key, text: o.value };
    });

    // update options
    if (field.value && !comboOptions.map((o) => o.key).includes(field.value)) {
        comboOptions.push({ key: field.value, text: field.value });
    }

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            <Label name={field.name} value={label} />
            <ComboBox
                text={field.value || ""}
                allowFreeform={true}
                autoComplete={"on"}
                options={comboOptions}
                disabled={disabled}
                //styles={comboBoxStyles}
                onChange={(e, selectedOption, index, value) => {
                    setFieldValue(field.name, value, true);
                }}
            />
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
        </div>
    );
}
