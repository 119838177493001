import React, { useMemo } from "react";
import { gql, useQuery } from "urql";
import { yesterday } from "../../../components/dateFormater";
import { DateForm } from "../../../common/dateForm";
import { useQueryState } from "react-router-use-location-state";

import { Grid, Column } from "../../../../../components/src";
import { InstrumentModelTypeEnum, TimeSeries, TradingManagerColumn } from "../../../types.generated";
import { numberFormat, twoDecPriceFormat } from "../../../../../common/src/utils/FormatFunctions";
import { TableGrouper } from "../../../components/react-table/TableGrouper";
import { emptyObjectId } from "../../../../../common/src";
import { keyBy } from "lodash";

const GET_DATA = gql`
    query tm($clientIds: [GraphQLObjectId!], $date: GraphQLDateString, $tsIds: [GraphQLObjectId!]) {
        tradingmanager(
            groupPositionsBy: AccountId
            filterZeroPositions: true
            filter: { clientIds: $clientIds, endDate: $date }
            lookThrough: false
            baseCurrency: SEK
        ) {
            name
            swaplab2Results(date: $date) {
                interestRateYieldDelta
                creditYieldDelta
            }
            modelType
            exposure
        }
        timeseries(filter: { idIn: $tsIds }, includeItems: true) {
            _id
            instrument {
                name
            }
            items {
                date
                value
            }
        }
    }
`;

const twoDecPctFormat = (d: any) => (typeof d === "number" ? numberFormat(Number(d), "# ##0.00%") : "");

// constants from python script
const CLIENT_DATA_BY_ID: Record<
    string,
    {
        name: string;
        riskFactors: { eq: number; ir: number; crIg: number; crHy: number };
        onlyCdsCredit: boolean;
        actualSeriesId: string;
        simSeriesId: string;
    }
> = {
    "58e64b9523d2772e1859b705": {
        name: "Captor Iris Bond",
        riskFactors: { eq: 0, ir: 1, crIg: 0, crHy: 0 },
        onlyCdsCredit: false,
        actualSeriesId: "5b72a10c23d27735104e0576",
        simSeriesId: "5813595971051506189ba416"
    },
    "605b2e5cc34cf5001154c90d": {
        name: "Captor Aster Global Credit",
        riskFactors: { eq: 0, ir: 1, crIg: 1, crHy: 0 },
        onlyCdsCredit: true,
        actualSeriesId: "606f1ad71e33b60011587f6a",
        simSeriesId: "5ebeab795bd37100116b84b3"
    },
    "62690a20071ef07765246144": {
        name: "Captor Aster Global Credit Short Term",
        riskFactors: { eq: 0, ir: 1, crIg: 1, crHy: 0 },
        onlyCdsCredit: true,
        actualSeriesId: "6310e1cb160b504c72dd91ea",
        simSeriesId: "618acd4f36516e22dcf7c970"
    },
    "62690582071ef0776524606c": {
        name: "Captor Aster Global High Yield",
        riskFactors: { eq: 0, ir: 1, crIg: 0, crHy: 1 },
        onlyCdsCredit: true,
        actualSeriesId: "638f681e0c2f4c8d28a13392",
        simSeriesId: "618ad72b36516e22dcf7d7c8"
    },
    "649d36255de897ec4079bbae": {
        name: "Captor Global Fixed Income",
        riskFactors: { eq: 0, ir: 1, crIg: 0.7, crHy: 0.3 },
        onlyCdsCredit: true,
        actualSeriesId: "65733b0114719695d6624c08",
        simSeriesId: "658d3d061b4f4b39f911f33b"
    },
    "649d4a855de897ec4079d879": {
        name: "Captor Perenne Short Term bond",
        riskFactors: { eq: 0, ir: 1, crIg: 0, crHy: 0 },
        onlyCdsCredit: false,
        actualSeriesId: "65a7f8b520a9b32a1860066c",
        simSeriesId: "64e4d0902c152c32d2637c1c"
    },
    "5b0d638cafcedd32f03a8ac7": {
        name: "Captor Dahlia Green Bond",
        riskFactors: { eq: 0, ir: 1, crIg: 1, crHy: 0 },
        onlyCdsCredit: false,
        actualSeriesId: "5b72ccea23d2772e9c014bbc",
        simSeriesId: "5eda23bbee52f9001178b34d"
    },
    "594a2cf723d2773014ec27b7": {
        name: "Captor Scilla Global Equity",
        riskFactors: { eq: 1, ir: 0, crIg: 0, crHy: 0 },
        onlyCdsCredit: false,
        actualSeriesId: "5c1115fbce5b131cf0b224fc",
        simSeriesId: "5e5fd21f1f97b0000195b204"
    }
};

const RISK_SCENARIOS_BY_NAME = {
    lehman: { start: "2008-09-15", end: "2008-10-14", eq: -0.15, ir: -0.004, crIg: 0.005, crHy: 0.02 },
    greece: { start: "2015-06-22", end: "2015-07-08", eq: -0.04, ir: -0.0025, crIg: 0.0015, crHy: 0.006 },
    covid: { start: "2020-02-28", end: "2020-03-31", eq: -0.16, ir: 0.001, crIg: 0.004, crHy: 0.03 },
    inflation: { start: "2021-12-30", end: "2022-06-30", eq: -0.22, ir: 0.0215, crIg: 0.006, crHy: 0.03 }
};

interface PerformanceTabProps {
    client: { _id: string; name: string };
}

export const ScenarioRiskTab = ({ client }: PerformanceTabProps): React.ReactElement => {
    const [endDate] = useQueryState("endDate", yesterday());

    const clientData = CLIENT_DATA_BY_ID[client._id];
    const tsIds: string[] = [];
    if (clientData && clientData.actualSeriesId) tsIds.push(clientData.actualSeriesId);
    if (clientData && clientData.simSeriesId) tsIds.push(clientData.simSeriesId);

    const [{ data, error, fetching }] = useQuery({
        query: GET_DATA,
        variables: { date: endDate, clientIds: client._id, tsIds },
        requestPolicy: "cache-and-network",
        pause: !client || client._id === emptyObjectId
    });

    const columns = useMemo(
        () => [
            {
                header: "Client",
                accessorKey: "name",
                size: 100
            },
            {
                header: "Scenario",
                accessorKey: "scenario",
                size: 50
            },
            {
                header: "Value",
                accessorKey: "value",
                size: 50,
                cell: (cellProps) => {
                    const value = cellProps.getValue();
                    if (value) return <div style={{ textAlign: "right" }}>{twoDecPctFormat(value)}</div>;
                    else return <div></div>;
                }
            },
            {
                header: "Actual",
                accessorKey: "actual",
                size: 50,
                cell: (cellProps) => {
                    const value = cellProps.getValue();
                    if (value) return <div style={{ textAlign: "right" }}>{twoDecPctFormat(value)}</div>;
                    else return <div></div>;
                }
            },
            {
                header: "Simulated",
                accessorKey: "simulated",
                size: 50,
                cell: (cellProps) => {
                    const value = cellProps.getValue();
                    if (value) return <div style={{ textAlign: "right" }}>{twoDecPctFormat(value)}</div>;
                    else return <div></div>;
                }
            }
        ],
        []
    );

    if (fetching) return <p>fetching...</p>;
    if (error) <pre>{JSON.stringify(error, null, 2)}</pre>;

    if (!CLIENT_DATA_BY_ID[client._id]) return <p>No scenario figures available for this client</p>;

    const riskFactors = clientData.riskFactors;

    const tsDataById: Record<string, TimeSeries> = keyBy(data.timeseries, "_id");

    const actualSeries = tsDataById[clientData.actualSeriesId];
    const simSeries = tsDataById[clientData.simSeriesId];

    const cdsModelsByType = { [InstrumentModelTypeEnum.CdsIndex]: true, [InstrumentModelTypeEnum.CdsBasket]: true };

    let total = 0;
    let cash = 0;

    let interestYieldDelta = 0;
    let creditYieldDelta = 0;

    for (const column of data.tradingmanager as TradingManagerColumn[]) {
        total += column.exposure;
        if (column.modelType === InstrumentModelTypeEnum.Balance) {
            cash += column.exposure;
            continue;
        }
        if (column.swaplab2Results) {
            // always add interest yield delta
            if (column.swaplab2Results.interestRateYieldDelta) {
                interestYieldDelta += column.swaplab2Results.interestRateYieldDelta;
            }

            // add if cds model onlyCdsCredit
            if (column.swaplab2Results.creditYieldDelta && clientData.onlyCdsCredit && cdsModelsByType[column.modelType]) {
                creditYieldDelta += column.swaplab2Results.creditYieldDelta;
            }

            // add for all models if !onlyCdsCredit
            if (column.swaplab2Results.creditYieldDelta && !clientData.onlyCdsCredit) {
                creditYieldDelta += column.swaplab2Results.creditYieldDelta;
            }
        }
    }

    const irDuration = (10000 * interestYieldDelta) / total;
    const crDuration = (10000 * creditYieldDelta) / total;

    const tableData: { name: string; scenario: string; value: number; simulated: number; actual: number }[] = [];

    const tsReturn = (startDate: string, endDate: string, ts: TimeSeries): number => {
        // we need a time series
        if (!ts) return null;
        // we need items
        if (!(Array.isArray(ts.items) && ts.items.length)) return null;
        const n = ts.items.length;
        // we need to be in range - since we now items in ascending order
        if (startDate < ts.items[0].date || endDate > ts.items[n - 1].date) return null;

        // now we loop - since we now items in ascending order
        let startValue: number = null;
        let endValue: number = null;

        for (let i = 0; i < n; i++) {
            const item = ts.items[i];
            if (item.date > endDate) break;
            endValue = item.value;
            if (item.date <= startDate) startValue = item.value;
        }

        // just in case
        if (startValue === null) return null;

        // we cannot calculate for zero or undefined end value
        if (!endValue) return null;

        return endValue / startValue - 1;
    };

    for (const [key, scenario] of Object.entries(RISK_SCENARIOS_BY_NAME)) {
        const eqAttribution = scenario.eq * riskFactors.eq;
        const irAttribution = -scenario.ir * riskFactors.ir * irDuration;
        const crIgAttribution = -scenario.crIg * riskFactors.crIg * crDuration;
        const crHyAttribution = -scenario.crHy * riskFactors.crHy * crDuration;

        const value = (total - cash) * eqAttribution + total * (irAttribution + crIgAttribution + crHyAttribution);

        const actualReturn = tsReturn(scenario.start, scenario.end, actualSeries);
        const simReturn = tsReturn(scenario.start, scenario.end, simSeries);

        tableData.push({
            name: clientData.name,
            scenario: key.toString(),
            value: value / total,
            actual: actualReturn,
            simulated: simReturn
        });
    }

    const scenarioTable: {
        scenario: string;
        start: string;
        end: string;
        equites: number;
        interest_rate: number;
        credit_investment_grade: number;
        credit_high_yield: number;
    }[] = [];
    for (const [key, scenario] of Object.entries(RISK_SCENARIOS_BY_NAME)) {
        scenarioTable.push({
            scenario: key,
            start: scenario.start,
            end: scenario.end,
            equites: scenario.eq,
            interest_rate: scenario.ir,
            credit_investment_grade: scenario.crIg,
            credit_high_yield: scenario.crHy
        });
    }

    return (
        <div>
            <div className="row mb-3 mt-3">
                <div className="col-xs-6 col-sm-4">
                    <DateForm defaultDateString={endDate} dateName={"endDate"}></DateForm>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Grid header="Scenarios" data={scenarioTable} sortable hideDownload={true} tableClassName="table-xs">
                        <Column field="scenario" />
                        <Column field="start" />
                        <Column field="end" />
                        <Column className="right" field="equites" />
                        <Column className="right" field="interest_rate" />
                        <Column className="right" field="credit_investment_grade" />
                        <Column className="right" field="credit_high_yield" />
                    </Grid>
                </div>
            </div>

            <div className="row">
                <div className="d-inline-flex flex-wrap mt-2">
                    <div className="me-3">
                        <h4>Results</h4>
                        <p>
                            Modified duration: {twoDecPriceFormat(irDuration)} years. Credit duration: {twoDecPriceFormat(crDuration)}{" "}
                            years.
                        </p>
                        {tableData ? (
                            <TableGrouper
                                columns={columns}
                                data={tableData}
                                expanded={{}}
                                groupBy={[]}
                                compactMode={true}
                                captionColumn="name"
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};
